import React, { useState, useRef } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { usePatientStore } from '../stores/patient.store';

const UploadImageDialog = ({ open, onClose, image, setImage }) => {
  const [src, setSrc] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: 'px', width: 350, height: 350 });
  const inputRef = useRef();
  const imgRef = useRef();
  const { onUploadFile, selectedPatient } = usePatientStore();

  const triggerUpload = () => {
    inputRef.current.click();
  }

  const onCropping = (newCrop) => {
    setCrop(newCrop);
  }

  const onCropComplete = (completedCrop) => {
    setCompletedCrop(completedCrop);
  }

  const saveImage = () => {

    const img = imgRef.current.target;
    const imageCanvas = document.createElement('canvas');
    const imageCtx = imageCanvas.getContext('2d');
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
  
    imageCanvas.width = Math.floor(crop.width * scaleX);
    imageCanvas.height = Math.floor(crop.height * scaleY);

    const cropX = Math.floor(completedCrop.x * scaleX);
    const cropY = Math.floor(completedCrop.y * scaleY);

    if (!imageCtx) {
      return;
    }
    imageCtx.translate( -cropX, -cropY);
    imageCtx.drawImage(
      img,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight,
    );


    imageCanvas.toBlob((blob) => {

      const file = new File([blob], 'avatar.png', { type: 'image/png'});
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'avatar');
        formData.append('patient', selectedPatient._id);
        formData.append('title', 'avatar');
        onUploadFile(formData, setImage);
        onClose();
      // }
    }, 'image/png', 1);
  }

  const onImageLoad = (img) => {
    imgRef.current = img;
    if (img.target.naturalWidth > 350 || img.target.naturalHeight > 350) {
      setCrop(makeAspectCrop({
        x: 0,
        y: 0,
        aspect: 1 / 1,
        width: 350,
        height: 350,
      }, 1 / 1));
      setImage(img.target);
    }
  }

  const clearOnClose = () => {
    setSrc(null);
    setCrop(null);
    setImage(null);
    onClose();
  }

  return (
    <Dialog open={open} onClose={clearOnClose} >
    {src ? (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        padding: 4,
      }}>
        <div id="imagen"></div>
        <ReactCrop
          crop={crop}
          onChange={onCropping}
          onComplete={(e) => onCropComplete(e)}
          aspect={1 / 1}
        >
          <img src={src} alt="file to crop" ref={imgRef} onLoad={onImageLoad} />
        </ReactCrop>
        <Button variant="contained" onClick={saveImage} >Guardar</Button>
      </Box>
    ) : (

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        padding: 4,
      }}>
        <Typography variant="h5"  >Sube tu foto</Typography>
        <input
          accept="image/*"
          type="file"
          ref={inputRef}
          style={{ display: 'none'}}
          onChange={(e) => {

            const reader = new FileReader();
            reader.addEventListener('load', () => {
              setImage(reader.result.toString());
            });
            setSrc(URL.createObjectURL(e.target.files[0]));
            reader.readAsDataURL(e.target.files[0]);

            setCrop(null);
          }}
        />
        <Button variant="contained" onClick={triggerUpload} >Subir foto</Button>
      </Box>
    )}
  </Dialog>
  )

}

export default UploadImageDialog;
