import { Box, Avatar } from '@mui/material';
import { PhotoCameraOutlined } from '@mui/icons-material';
import { BASE_BEIGE, BASE_BROWN } from '../utils/constants';
import UploadImageDialog from './upload-image';
import { useState } from 'react';

const PatientPhoto = ({ patient }) => {
  console.log(patient);
  const [openPictureCropper, setOpenPictureCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);



  if (!patient) return (
    <div className="patient-photo">
      <img src="https://via.placeholder.com/150" alt="Patient" />
    </div>
  );

  return (
    <>
      <UploadImageDialog
        open={openPictureCropper}
        onClose={() => setOpenPictureCropper(false)}
        image={croppedImage}
        setImage={setCroppedImage}
      />
      <Box sx={{
        borderWidth: 1,
        borderColor: '#B0704D',
        borderStyle: 'solid',
        borderRadius: '50%',
        padding: 1,
        width: {xs: 50, md: 100},
        height: {xs: 50, md: 100},
      }} onClick={() => setOpenPictureCropper(true)}>
        <Avatar sx={{
          width: {xs: 50, md: 100},
          height: {xs: 50, md: 100},
          backgroundColor: BASE_BEIGE,
          color: BASE_BROWN,
          fontSize: {xs: 20, md: 40},
        }}>
          {croppedImage !== null || patient.url ? (
            <Box>
              <img src={croppedImage !== null ? croppedImage : patient.url} alt="Patient" width="100%" />
            </Box>

          ) : (
            <Box onClick={() => setOpenPictureCropper(true)}>
              <PhotoCameraOutlined sx={{ fontSize: {xs: 20, md: 40} }} />
            </Box>
          )}
        </Avatar>
      </Box>
    </>
  );
}

export default PatientPhoto;
