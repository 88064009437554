import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import LandingPage from '../pages/landing.page';
import LoginPage from '../pages/login.page';

import { AnimatePresence } from 'framer-motion';
import DashboardPage from '../pages/dashboard.page';
import PatientPage from '../pages/patient.page';
import CreatePatient from './create-patient';
import Terms from '../pages/terms.page';
import { MyRoutes } from '../utils/constants';

const AnimatedRoutes = () => {
  const location = useLocation();
    return (
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path={MyRoutes.Profile} element={<DashboardPage />} />
          <Route path={MyRoutes.CreatePatient} element={<CreatePatient />} />
          <Route path={MyRoutes.Patient} element={<PatientPage />} />
          <Route path={MyRoutes.Terms} element={<Terms />} />
        </Routes>
      </AnimatePresence>
    );
}

export default AnimatedRoutes;
