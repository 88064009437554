import React from 'react';
import { Box, Link, Menu, MenuItem, Divider } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useUserStore } from '../stores/user.store';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { BASE_BEIGE, BASE_BLUE, MyRoutes } from '../utils/constants';
import logo from '../assets/images/logo.png';

const Navbar = () => {

    const linkItem = {
        backgroundColor: '#0b2c69',
        textDecoration: 'none',
        color: 'white !important',
        fontweight: 400,
        marginY: 'auto',
        paddingX: 2,
        paddingY: 1,
        borderRadius: 2,
        '&:hover': {
            textDecoration: 'underline',
        }
    }

  const { user, onLogout } = useUserStore();
  const path = useLocation().pathname;

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    onLogout(() => window.location.reload());
    setAnchorEl(null);
  }

  const styleLink = (path, label) => {
    return {
        ...linkItem,
        textDecoration: path === label ? 'underline' : 'none',
        fontWeight: path === label ? 800 : 400,
    }
}


const loggedInMenu = () => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            height: '100%',
        }}>
            <Link
                sx={{
                    ...linkItem,
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    '&:hover': {
                        textDecoration: 'none',
                    }
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                {user.name}
                {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Link>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={RouterLink} to={MyRoutes.Profile} onClick={handleClose} >Mi perfil</MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            </Box>
    )
}

if (path === '/login') return null;

    return (
        <Box sx={{
            backgroundColor: path === '/' ? BASE_BEIGE : BASE_BLUE,
            boxSizing: 'border-box',
            paddingY: 3,
            paddingX: 5,
            display: 'flex',
            color: '#fff',
        }} >
            <Link component={RouterLink} to="/"
                sx={{}}
            
            >
                <img src={logo} alt="logo" style={{ width: 100, height: 100 }} />
            </Link>
            <Box sx={{ marginLeft: 'auto'}}>
                {user ? loggedInMenu() : 
                <Link component={RouterLink} to="/login"
                    sx={styleLink(path, '/login')}
                >Iniciar sesión/Registrarse</Link>}
            </Box>
        </Box>
    );
}

export default Navbar;
