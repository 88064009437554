export const BASE_BLUE = '#0b2c69';
export const BASE_WHITE = '#fff';
export const BASE_BROWN = '#B0704D';
export const BASE_BEIGE =  '#F0EFEB';
export const MyRoutes = {
  Profile: '/mi-perfil',
  CreatePatient: '/mi-perfil/crear-paciente',
  Patient: 'paciente/:name',
  Terms: '/terminos',
  Login: '/login',
}